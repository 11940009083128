import * as React from "react"
import Values from "../ggv/Values"
import Founder from "../components/Founder.ggv"
import Demo from "../components/Demo"
import Problems from "../ggv/Problems"
import Customers from "../components/Customers"
import RoadMap from "../components/RoadMap"
import Structure from "../components/Structure"
import Retool from "../components/Retool"
import Solve from "../components/Solve"
import Solutions from "../ggv/Solutions"
import Head from "../components/Head"
import FAQ from "../components/FAQ.ggv"
import ToolsFeature from "../components/ToolsFeature"
import UserCases from "../components/UserCases"
import * as style from '../pages/bp.ggv.css'
import CostStructure from "../ggv/CostStructure"
import RevenueStreams from "../ggv/RevenueStreams"
import UnfairAdvantage from "../ggv/UnfairAdvantage"
// import 'mvp.css'
// import 'charts.css'

const pageStyle = {
  // color: "#232129",
  // paddingBottom: '300px',
  backgroundColor: "rgb(244, 243, 251)"
  // fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const logoStyles = {
  width: "100px"
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyle} id="ggv">
      <title>Business Plan</title>
      <div className="p-3 ">
        
      
      <header style={{'padding': '2rem 0'}}> <h1>商业计划书</h1> <img src="http://nbimgs.breadtrip.com/8UkomLvO8GUbeU219CpPklShaGXhmXnK/logo.png" style={logoStyles}/> <p className="mt-5"> <small>updated at 2022-05-03</small> </p> </header>
      
      </div>

      <Values></Values>

      <Problems></Problems>

      <Solutions></Solutions>

      <Customers></Customers>

      <CostStructure></CostStructure>

      <RevenueStreams></RevenueStreams>

      <UnfairAdvantage></UnfairAdvantage>
    
      <Founder></Founder>

      <div className="p-3">
      <header> <h1>融资需要</h1> <p>本轮计划出让10%～20%的股权，融资100～200万美金。</p> </header>
      <figure> <img src="http://nbimgs.breadtrip.com/ERERuMvi0ufHC1zpoG123zmjiHiopGIA/funding.jpeg" alt="Funding" /> </figure>
      </div>     
      
      <div className="p-3 ">
      <RoadMap></RoadMap>        
      </div>     

      <Retool></Retool>
      
      <div className="p-3 ">
      <FAQ></FAQ>        
      </div>     
      
      <UserCases></UserCases>

      <div className="p-3">
        {/* <a href="http://localhost:3000/">Check Demo List</a>   */}
        <img src="http://nbimgs.breadtrip.com/vOB2MzaOyYIEfeW3bHB7DM9vJ1AzLkEU/%E4%BC%81%E4%B8%9A%E6%95%B0%E6%8D%AE%E6%B5%81%E7%A8%8B%E7%AE%80%E4%BB%8B.png"></img>
        <img src="http://nbimgs.breadtrip.com/2RXQhdnWTbo1AWpUkLWR5IDP8u6YAR1H/%E6%9E%B6%E6%9E%84%E8%AE%BE%E8%AE%A1%202.png"></img>
      </div>     

      <div className="p-3">

      <Demo></Demo>
        
      </div>     

      <div className="p-3">

      <header> <h1>Thanks</h1> </header>
        
      </div>     

      
      

      


      
      
    </main>
  )
}

export default IndexPage
